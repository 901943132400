:root {
    --themeColor: #0B3379
}

a {
    text-decoration: none;
    margin-right: 20px;
}

.pageTitle {
    display: inline-block;
}

.pageHeading {
    display: inline-grid;
    grid-template-columns: 1fr;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    position: relative;
}

.pageHeading>a {
    position: absolute;
    left: -50px;
}

.loaderDiv {
    display: block;
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 50px;
    margin-top: 200px;
}

.MuiCircularProgress-indeterminate,
.MuiSvgIcon-root {
    color: var(--themeColor);
}

tbody tr {
    cursor: pointer;
}

.MuiListItemButton-root.active {
    background-color: var(--themeColor);
    color: white;
}

.MuiListItemButton-root.active:before {
    background-color: white;
}


.MuiButton-containedPrimary {
    background-color: var(--themeColor);
}

.customDivider {
    background-color: lightgray;
    color: inherit;
    padding: 10px;
    margin: 10px 0px;
}

.galleryBox {
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    overflow: hidden;
}

.galleryBox img {
    height: 200px;
    width: 100%;
    cursor: pointer;
}

.galleryBox img.selected {
    border: 8px solid var(--themeColor);
}

.galleryContainer.imageSelection img {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

p.MuiFormHelperText-root {
    text-transform: capitalize;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.imageBox {
    position: relative;
    display: flex;
}

.imageSelectionBox {
    display: none;
    position: absolute;
}

.galleryContainer.imageSelection .imageSelectionBox {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
}

.imageSelectionBox.Mui-checked:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    border: 8px solid var(--themeColor);
}

.floatingdiv {
    position: fixed;
    bottom: 60px;
    right: 30px;
    z-index: 1203;
}

.counterSpan {
    background-color: var(--themeColor);
    color: white;
    margin-right: 10px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
}

.modalForm {
    padding: 10px 10px;
}

/* .MuiButtonBase-root{
    color: white;
    background-color: var(--themeColor);
} */

.pdfImgSelect {
    width: 100%;
}

.pdfMainDiv {
    position: relative;
}

.pdfMainDiv>span.pdfSelctRadio {
    position: absolute;
}

.pdfMainDiv>span.pdfImgSelect>input {
    height: 100%;
    width: 100%;
}

.PdfImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.TitleClass {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    background-color: white;
    align-items: center;
    position: fixed;
    z-index: 1202;
    bottom: 0;
    border-top: 1px solid rgba(145, 158, 171, 0.24);
}

.footer a {
    color: #0B3379;
}

.tableCellHover {
    cursor: pointer;
}